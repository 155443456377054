import { useAuthStore } from '@tundr/auth';
import { NotFound } from '@tundr/ui-kit';
import { RouteRecordRaw } from 'vue-router';
import AppRouter from '../../modules/app/AppRouter.vue';
import { companiesRoutesConfig } from '../../modules/app/company/routes/company-routes-config.ts';
import { reimbursementsRoutesConfig } from '../../modules/app/reimbursement/routes/reimbursement-routes-config.ts';
import { suppliersRoutesConfig } from '../../modules/app/suppliers/routes/suppliers-routes.ts';
import { authRoutesConfig } from '../../modules/auth/routes/auth-routes.config.ts';
import { appRoutesDictionary } from './routes.dictionary';
import { bankTransfersRoutesConfig } from '../../modules/app/bank-transfers/routes/bank-transfers-routes.ts';
import { subscriptionRoutesConfig } from '../../modules/app/subscription/routes/subscription-routes-config.ts';
import { reportsRoutesConfig } from '../../modules/app/reports/routes/reports-routes-config.ts';
import { overBudgetEmployeeRoutesConfig } from '../../modules/app/over-budget-employee/routes/over-budget-employee-routes.config.ts';
import { billingRoutesConfig } from '../../modules/app/billing/routes/billing-routes-config.ts';
import { paymentsRoutesConfig } from '../../modules/app/payments/routes/payments-routes.ts';
import { fringeRoutesConfig } from '../../modules/app/fringe/routes/fringe-routes-config.ts';
import { cardOrderRoutesConfig } from '../../modules/app/card-orders/routes/card-order-routes-config.ts';
import { usersRoutesConfig } from '../../modules/app/users/routes/users-routes.ts';
import { supplierVouchersRoutesConfig } from '../../modules/app/supplier-voucher-reports/routes/supplier-vouchers-routes-config.ts';
import { paymentValidationKeywordsRoutesConfig } from '../../modules/app/payment-validation-keywords/routes/payment-validation-keywords-routes.ts';

export const routes: RouteRecordRaw[] = [
  { ...appRoutesDictionary.root.notFound, component: NotFound },
  {
    ...appRoutesDictionary.root.root,
    redirect: { name: appRoutesDictionary.auth.root.name },
  },
  {
    ...authRoutesConfig,
    beforeEnter: () => {
      const authStore = useAuthStore();
      return !authStore.isLogged || { name: appRoutesDictionary.root.app.name };
    },
  },
  {
    ...appRoutesDictionary.root.app,
    redirect: { name: appRoutesDictionary.users.root.name },
    component: AppRouter,
    children: [
      suppliersRoutesConfig,
      companiesRoutesConfig,
      reimbursementsRoutesConfig,
      reportsRoutesConfig,
      bankTransfersRoutesConfig,
      subscriptionRoutesConfig,
      overBudgetEmployeeRoutesConfig,
      billingRoutesConfig,
      paymentsRoutesConfig,
      fringeRoutesConfig,
      cardOrderRoutesConfig,
      usersRoutesConfig,
      supplierVouchersRoutesConfig,
      paymentValidationKeywordsRoutesConfig,
    ],
    beforeEnter: () => {
      const authStore = useAuthStore();

      return authStore.isLogged || { name: appRoutesDictionary.auth.root.name };
    },
  },
];
