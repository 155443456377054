<script lang="ts" setup>
  import { computed } from 'vue';
  import { useFilters } from '../../../composables';
  import { Filter, FiltersForTable } from '../../../store/';
  import { FilterTypes } from '../../../utils';
  import { ActiveFilterChip } from '../ActiveFilterChip';
  import { activeFiltersRowClass } from './ActiveFiltersRow.css';

  const props = defineProps<{
    filterNamespace: string;
  }>();

  const emit = defineEmits<{
    removeFilter: [filter: Filter];
    editFilters: [filters?: FiltersForTable];
  }>();

  const currentFilterStore = useFilters(props.filterNamespace);

  const activeFilters = computed(() => currentFilterStore.getFilters.value);

  const onRemoveFilter = (filter: Filter) => {
    currentFilterStore.removeFilter(filter.filterFieldName);
    emit('removeFilter', filter);
  };
</script>

<template>
  <div
    :class="activeFiltersRowClass"
    v-if="activeFilters && activeFilters.length > 0"
  >
    <template v-for="filter of activeFilters" :key="filter.filterFieldName">
      <ActiveFilterChip
        v-if="filter.filterType !== FilterTypes.TEXT_SEARCH"
        :filterNamespace="filterNamespace"
        v-bind="{ filter }"
        @removeFilter="onRemoveFilter"
      >
        <template #additional-filters>
          <slot name="additional-filters"></slot>
        </template>
      </ActiveFilterChip>
    </template>
  </div>
</template>
