import { useLocalStorage, useSessionStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { FilterTypes, reviveDates } from '../utils';
import {
  ApplyFilterValueProps,
  Filter,
  SearchTextFilterProps,
} from './filters.types';

interface FilterStore {
  filters: Record<string, Filter[]>;
}

export const useFiltersStore = defineStore('filters', () => {
  /*
    We use this map to keep track of the filters version.
    When we need to invalid a filter stored in localStorage, we can just increase the version number
  */
  const filtersVersionMap = useLocalStorage<Record<string, string>>(
    'filtersVersionMap',
    {},
  );

  /*
    We use this to keep track of the filters that are stored in localStorage.
    The filters are stored under "filters[filterNamespace] = Filter[]" structure
  */
  const filters = useSessionStorage<FilterStore['filters']>(
    'filters',
    {},
    {
      serializer: {
        read: (v) => {
          if (v) {
            /*
              We take the value from localStorage and parse it to restore dates used in components
            */
            const parsedStore = reviveDates(JSON.parse(v));
            return parsedStore;
          }
          return {};
        },
        write: (v) => JSON.stringify(v),
      },
    },
  );

  /**
   *   We check if a filter is active or not exluding the text search filter from this logic
   * @param entityName
   * @param filterFieldName
   * @returns
   */
  const filterIsActive = (
    entityName: string,
    filterFieldName: string,
  ): boolean => {
    return (
      filters.value[entityName] &&
      !!filters.value[entityName].find((filter: Filter) => {
        if (
          filter.filterType === FilterTypes.TEXT_SEARCH &&
          !filter.modelValue
        ) {
          return false;
        }
        return filter.filterFieldName === filterFieldName;
      })
    );
  };

  /**
   * When we initialize a filter, we reset the version stored in localStorage associated with it
   * and set the filters to an empty array
   * @param entityName
   * @returns void
   */
  const initializeFilter = (entityName: string) => {
    if (filtersVersionMap.value[entityName]) {
      delete filtersVersionMap.value[entityName];
    }
    filters.value = { ...filters.value, [entityName]: [] };
  };

  const setFilterVersion = (entityName: string, filterVersion: string) => {
    filtersVersionMap.value = {
      ...filtersVersionMap.value,
      [entityName]: filterVersion,
    };
  };

  /**
   * We can initialize a filter with an empty model value
   * @param entityName
   * @param newFilter
   */
  const addFilter = (entityName: string, newFilter: Filter) => {
    if (!filterIsActive(entityName, newFilter.filterFieldName)) {
      filters.value[entityName] = [
        ...(filters.value[entityName] || []),
        newFilter,
      ];
    } else {
      console.warn('Filter already exists');
    }
  };

  const removeFilter = (entityName: string, filterFieldName: string) => {
    const newFilters = filters.value[entityName].filter(
      (filter) => filter.filterFieldName !== filterFieldName,
    );
    filters.value = { ...filters.value, [entityName]: newFilters };
  };

  const applyFilterValue = (
    payload: ApplyFilterValueProps & { entityName: string },
  ): void => {
    const { entityName, filterFieldName, value, filterType } = payload;

    /**
     * We check if we have a text search filter active.
     * If we do, we just update the modelValue, if not, we add all the filter object in the store
     */
    if (filterType === FilterTypes.TEXT_SEARCH) {
      const searchFilter: SearchTextFilterProps = {
        filterNamespace: entityName,
        filterFieldName: filterFieldName || FilterTypes.TEXT_SEARCH,
        filterLabel: FilterTypes.TEXT_SEARCH,
        modelValue: value as SearchTextFilterProps['modelValue'],
        filterType: FilterTypes.TEXT_SEARCH,
      };

      const searchFilterInStore =
        filters.value[entityName] &&
        filters.value[entityName].find(
          (filter) => filter.filterType === FilterTypes.TEXT_SEARCH,
        );

      if (searchFilterInStore) {
        searchFilterInStore.modelValue = value;
      } else {
        filters.value[entityName] = [
          ...(filters.value[entityName] || []),
          searchFilter,
        ];
      }
      return;
    }

    if (!filterIsActive(entityName, filterFieldName)) {
      console.warn('Filter does not exist');
      return;
    }
    const filter = filters.value[entityName].find(
      (filter) => filter.filterFieldName === filterFieldName,
    );

    if (filter) {
      filter.modelValue = value;
    }

    filters.value = {
      ...filters.value,
      [entityName]: filters.value[entityName],
    };
  };

  const resetAllFilters = () => {
    filters.value = {};
    sessionStorage.removeItem('filters');
  };

  return {
    applyFilterValue,
    filterIsActive,
    filters,
    initializeFilter,
    filtersVersionMap,
    setFilterVersion,
    addFilter,
    removeFilter,
    resetAllFilters,
  };
});
