import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const paymentValidationKeywordsRoutesDictionary =
  moduleRouteDictionaryFactory(
    'paymentValidationKeywords',
    'paymentValidationKeywords',
    {
      list: 'list',
      create: 'create',
      edit: 'edit/:id',
    },
  );
