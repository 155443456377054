<script lang="ts" setup>
  import { ref } from 'vue';
  import { useFilters } from '../../../../composables';
  import { FilterTypes, simulateEscapeKeyPress } from '../../../../utils';
  import ApplyFilterButton from '../../ApplyFilterButton/ApplyFilterButton.vue';
  import { TextInput } from '@tundr/ui-kit';

  import { searchInputClass } from '../../../../../../../ui-kit/src/lib/form/SearchInput/SearchInput.css.ts';

  type searchValueType = string | number | undefined;

  const props = defineProps<{
    filterNamespace: string;
    filterLabel: string;
    filterFieldName: string;
    modelValue?: string | number;
    label?: string;
    placeholder?: string;
    type?:
      | 'text'
      | 'password'
      | 'url'
      | 'tel'
      | 'search'
      | 'number'
      | 'time'
      | undefined;
  }>();

  defineOptions({
    inheritAttrs: false,
  });

  const currentFilterStore = useFilters(props.filterNamespace);

  const inputValue = ref<searchValueType>(props.modelValue);

  const emit = defineEmits<{
    'update:modelValue': [searchValueType];
  }>();

  const onApplyFilter = () => {
    emit('update:modelValue', inputValue.value);
    currentFilterStore.applyFilterValue({
      filterFieldName: props.filterFieldName,
      value: inputValue.value,
      filterType: FilterTypes.TYPED_TEXT,
    });
    simulateEscapeKeyPress();
  };

  const handleChange = (val: string) => {
    inputValue.value = val;
    emit('update:modelValue', val);
  };
</script>

<template>
  <div>
    <TextInput
      :class="searchInputClass"
      name="filterTypedText"
      :label="filterLabel"
      :modelValue="modelValue"
      :placeholder="placeholder || ''"
      @update:model-value="handleChange"
      :autofocus="true"
      :type="type || 'text'"
    />
  </div>
  <ApplyFilterButton :disabled="!inputValue" @click="onApplyFilter" />
</template>
