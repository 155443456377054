<script setup lang="ts">
  import { computed } from 'vue';
  import { Filter } from '../../../../store';
  import { FilterTypes } from '../../../../utils/filters/filters-utils';
  import DateRangeFilter from '../DateRangeFilter/DateRangeFilter.vue';
  import SelectMultipleFilter from '../SelectMultipleFilter/SelectMultipleFilter.vue';
  import SelectOneFilter from '../SelectOneFilter/SelectOneFilter.vue';
  import SingleDateFilter from '../SingleDateFilter/SingleDateFilter.vue';
  import TypedTextFilter from '../TypedTextFilter/TypedTextFilter.vue';

  export type FilterByTypeProps = Filter & { filterNamespace: string };

  const props = defineProps<FilterByTypeProps>();

  const sidebarItemComponent = computed(() => {
    switch (props.filterType) {
      case FilterTypes.DATE_RANGE:
        return DateRangeFilter;
      case FilterTypes.DATE:
        return SingleDateFilter;
      case FilterTypes.SELECT:
        return SelectOneFilter;
      case FilterTypes.SELECT_MULTIPLE:
        return SelectMultipleFilter;
      case FilterTypes.TYPED_TEXT:
        return TypedTextFilter;
      default:
        return null;
    }
  });
</script>

<template>
  <slot
    name="additional-filters"
    v-if="props.filterType == FilterTypes.SELECT_PAGINATED"
  >
  </slot>
  <div v-else>
    <component
      v-if="sidebarItemComponent"
      :is="sidebarItemComponent"
      v-bind="{ ...$attrs, ...(props as any) }"
    />
  </div>
</template>
