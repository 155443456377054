<script lang="ts" setup>
  import { DatetimeFormatType, useLocale } from '@tundr/i18n';
  import {
    ButtonVariant,
    colClass,
    rowCenterClass,
    rowClass,
  } from '@tundr/theme';
  import {
    Button,
    ButtonSizes,
    ButtonWidthVariant,
    DatePicker,
    InputWidthVariant,
  } from '@tundr/ui-kit';
  import { isAfter, isEqual, isWithinInterval } from 'date-fns';
  import { computed, ref } from 'vue';
  import { useFilters } from '../../../../composables';
  import { FilterTypes, simulateEscapeKeyPress } from '../../../../utils';
  import ApplyFilterButton from '../../ApplyFilterButton/ApplyFilterButton.vue';
  import {
    dateInputErrorClass,
    dateRangeConstraintsClass,
    dateRangeFilterWrapperClass,
    dateRangeInputClass,
  } from './DateRangeFilter.css';

  const { t, d } = useLocale('table');

  const props = defineProps<{
    filterNamespace: string;
    filterLabel: string;
    filterFieldName: string;
    fromKey?: string;
    toKey?: string;
    startDate?: {
      min?: Date;
      max?: Date;
    };
    endDate?: {
      min?: Date;
      max?: Date;
    };
    modelValue?: {
      startDate: Date;
      endDate: Date;
    };
  }>();

  defineOptions({
    inheritAttrs: false,
  });

  const currentFilterStore = useFilters(props.filterNamespace);

  const startDateModel = ref<Date | undefined>(props.modelValue?.startDate);
  const endDateModel = ref<Date | undefined>(props.modelValue?.endDate);

  const emit = defineEmits<{
    'update:modelValue': [{ startDate?: Date; endDate?: Date }];
  }>();

  const onApplyFilter = () => {
    emit('update:modelValue', {
      startDate: startDateModel.value,
      endDate: endDateModel.value,
    });

    currentFilterStore.applyFilterValue({
      filterType: FilterTypes.DATE_RANGE,
      filterFieldName: props.filterFieldName,
      fromKey: props.fromKey,
      toKey: props.toKey,
      value: {
        startDate: startDateModel.value,
        endDate: endDateModel.value,
      },
    });

    simulateEscapeKeyPress();
  };

  const startMinDate = computed(() => {
    return (props.startDate?.min as Date) || undefined;
  });
  const startMaxDate = computed(() => {
    return endDateModel.value
      ? endDateModel.value
      : (props.startDate?.max as Date) || undefined;
  });

  const endMinDate = computed(() => {
    return startDateModel.value
      ? startDateModel.value
      : (props.endDate?.min as Date) || undefined;
  });
  const endMaxDate = computed(() => {
    return (props.endDate?.max as Date) || undefined;
  });

  const startDateIsValid = computed(() => {
    return startDateModel.value && startMinDate.value && startMaxDate.value
      ? isWithinInterval(startDateModel.value, {
          start: startMinDate.value,
          end: startMaxDate.value,
        })
      : true;
  });

  const endDateIsValid = computed(() => {
    return endDateModel.value && endMinDate.value && endMaxDate.value
      ? isWithinInterval(endDateModel.value, {
          start: endMinDate.value,
          end: endMaxDate.value,
        })
      : true;
  });

  const isValidSelection = computed(() => {
    return (
      (startDateIsValid.value &&
        endDateIsValid.value &&
        isAfter(endDateModel.value!, startDateModel.value!)) ||
      isEqual(endDateModel.value!, startDateModel.value!) ||
      startDateIsValid.value ||
      endDateIsValid.value
    );
  });

  const onSetLastMonth = () => {
    const today = new Date();
    today.setHours(today.getHours() - 1);
    const lastMonth = new Date();
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    lastMonth.setHours(lastMonth.getHours() + 1);
    startDateModel.value = lastMonth;
    endDateModel.value = today;
  };

  const onSetLastYear = () => {
    const today = new Date();
    today.setHours(today.getHours() - 1);
    const lastYear = new Date();
    lastYear.setFullYear(lastYear.getFullYear() - 1);
    lastYear.setHours(lastYear.getHours() + 1);
    startDateModel.value = lastYear;
    endDateModel.value = today;
  };
</script>

<template>
  <div :class="dateRangeFilterWrapperClass">
    <div>{{ filterLabel }}</div>
    <div :class="rowCenterClass">
      <Button
        :variant="ButtonVariant.transparentOutlined"
        :width="ButtonWidthVariant.MIN"
        @click="onSetLastYear"
        :size="ButtonSizes.SMALL"
        >{{ t('table.filters.date_range.last_year') }}
      </Button>
      <Button
        :variant="ButtonVariant.transparentOutlined"
        :width="ButtonWidthVariant.MIN"
        @click="onSetLastMonth"
        :size="ButtonSizes.SMALL"
        >{{ t('table.filters.date_range.last_month') }}
      </Button>
    </div>
    <div :class="rowClass">
      <div :class="colClass">
        <DatePicker
          :class="dateRangeInputClass"
          :inputClass="startDateIsValid ? '' : dateInputErrorClass"
          v-model="startDateModel"
          :width="InputWidthVariant.MIN"
          :label="t('table.filters.date_range.start_date.label')"
          :placeholder="t('table.filters.date_range.start_date.placeholder')"
          :name="`${filterFieldName}-start`"
          required
          :max-date="startMaxDate"
          :min-date="startMinDate"
          autofocus
        />
        <div :class="dateRangeConstraintsClass">
          <span v-if="startMinDate"
            >{{ t('table.filters.date_range.min_date') }}:
            {{ d(startMinDate, DatetimeFormatType.SHORT_DATE) }}</span
          >
          <span v-if="startMaxDate"
            >{{ t('table.filters.date_range.max_date') }}:
            {{ d(startMaxDate, DatetimeFormatType.SHORT_DATE) }}</span
          >
        </div>
      </div>
      <div :class="colClass">
        <DatePicker
          :class="dateRangeInputClass"
          :inputClass="endDateIsValid ? '' : dateInputErrorClass"
          v-model="endDateModel"
          :width="InputWidthVariant.MIN"
          :label="t('table.filters.date_range.end_date.label')"
          :placeholder="t('table.filters.date_range.end_date.placeholder')"
          :name="`${filterFieldName}-end`"
          required
          :max-date="endMaxDate"
          :min-date="endMinDate"
        />
        <div :class="dateRangeConstraintsClass">
          <span v-if="endMinDate"
            >{{ t('table.filters.date_range.min_date') }}:
            {{ d(endMinDate, DatetimeFormatType.SHORT_DATE) }}</span
          >
          <span v-if="endMaxDate"
            >{{ t('table.filters.date_range.max_date') }}:
            {{ d(endMaxDate, DatetimeFormatType.SHORT_DATE) }}</span
          >
        </div>
      </div>
    </div>
    <ApplyFilterButton :disabled="!isValidSelection" @click="onApplyFilter" />
  </div>
</template>
