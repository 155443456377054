import { useWindowSize } from '@vueuse/core';
import { computed, ComputedRef, getCurrentInstance, useSlots } from 'vue';
import {
  ClickRowArgument,
  DataTableProps,
} from '../components/DataTable/DataTable.types';

type DatatableEmits =
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  (evt: 'rowClick', row: any) => any;

export type DataTableInternalsProps = {
  tableMinHeight: number;
  tableHeight: DataTableProps['height'];
  emits: DatatableEmits;
};

export type DataTableInternals = {
  tableCustomHeight: ComputedRef<number>;
  itemsSlotsNames: ComputedRef<string[]>;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onRowClick: (row: any) => any;
  rowIsClickable: ComputedRef<boolean>;
  resetTableScroll: () => void;
};

export function useDataTableInternals(
  props: DataTableInternalsProps,
): DataTableInternals {
  const slots = useSlots();

  const ITEM_SLOT_PREFIX = 'item-';
  const EXPANDED_ITEM_SLOT_PREFIX = 'expand';

  const itemsSlotsNames = computed(() => {
    return Object.keys(slots).filter((slotName) => {
      return (
        slotName.startsWith(ITEM_SLOT_PREFIX) ||
        slotName.startsWith(EXPANDED_ITEM_SLOT_PREFIX)
      );
    });
  });

  const { height: windowHeight } = useWindowSize();
  const finalTableHeight = computed(() => {
    return Math.max(
      props.tableHeight ?? windowHeight.value * 0.6,
      props.tableMinHeight,
    );
  });

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const onRowClick = (rowData: ClickRowArgument<Record<string, any>>): any => {
    props.emits('rowClick', rowData);
  };

  const rowIsClickable = computed<boolean>(() => {
    const hasClickEventListener = computed(
      () => !!getCurrentInstance()?.vnode.props?.['onRowClick'],
    );
    return hasClickEventListener.value;
  });

  const resetTableScroll = () => {
    const tabelHtmlEl = document.querySelector('.vue3-easy-data-table__main');
    // reset scroll position
    if (tabelHtmlEl) {
      tabelHtmlEl.scrollTop = 0;
    }
  };

  return {
    tableCustomHeight: finalTableHeight,
    itemsSlotsNames,
    onRowClick,
    rowIsClickable,
    resetTableScroll,
  };
}
