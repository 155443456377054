import { RouteRecordRaw } from 'vue-router';
import { suppliersRoutesDictionary } from './suppliers-routes.dictionary.ts';

export const suppliersRoutesConfig: RouteRecordRaw = {
  ...suppliersRoutesDictionary.suppliers.root,
  redirect: { name: suppliersRoutesDictionary.suppliers.list.name },
  children: [
    {
      ...suppliersRoutesDictionary.suppliers.list,
      component: () => import('../pages/List/SuppliersListPage.vue'),
    },
    {
      ...suppliersRoutesDictionary.suppliers.create,
      component: () => import('../pages/Create/CreateSupplierPage.vue'),
    },
    {
      ...suppliersRoutesDictionary.suppliers.update,
      component: () => import('../pages/Edit/EditSupplierPage.vue'),
      props: true,
    },
    {
      ...suppliersRoutesDictionary.suppliers.createLocation,
      component: () =>
        import(
          '../pages/Detail/SupplierLocations/CreateLocation/CreateLocation.vue'
        ),
      props: true,
    },
    {
      ...suppliersRoutesDictionary.suppliers.editLocation,
      component: () =>
        import(
          '../pages/Detail/SupplierLocations/EditLocation/EditLocation.vue'
        ),
      props: true,
    },
    {
      ...suppliersRoutesDictionary.suppliers.createService,
      component: () =>
        import(
          '../pages/Detail/SupplierServices/CreateService/CreateService.vue'
        ),
      props: true,
    },
    {
      ...suppliersRoutesDictionary.suppliers.editService,
      component: () =>
        import('../pages/Detail/SupplierServices/EditService/EditService.vue'),
      props: true,
    },
    {
      ...suppliersRoutesDictionary.suppliers.detail,
      component: () => import('../pages/Detail/DetailSupplierPage.vue'),
      props: true,
    },
    {
      ...suppliersRoutesDictionary.suppliers.voucherCodes,
      component: () =>
        import(
          '../pages/Detail/SupplierVouchers/SupplierVouchersDetail/SupplierVouchersDetail.vue'
        ),
      props: true,
    },
  ],
};
