<script setup lang="ts">
  import { useLocale } from '@tundr/i18n';
  import { themeVars } from '@tundr/theme';
  import noResults from '@tundr/theme-assets/images/no-results.svg';
  import { Loader, Result } from '@tundr/ui-kit';
  import { computed, ref, useAttrs, useSlots } from 'vue';
  import EasyDataTable, { ServerOptions, SortType } from 'vue3-easy-data-table';
  import 'vue3-easy-data-table/dist/style.css';
  import { useFilters } from '../../composables';
  import { useDataTableInternals } from '../../composables/use-data-table-internals.ts';
  import { FiltersForTable, OnChangeFiltersEvent } from '../../store';
  import { FilterTypes } from '../../utils';
  import {
    headerClassNameFunction,
    itemClassNameFunction,
    ITEMS_PER_PAGE_DEFAULT_OPTIONS,
    rowClassNameFunction,
  } from '../../utils/table-classes.utils.ts';
  import {
    centeredContainerClass,
    dataTableContainerClass,
    tableContainerClass,
    tableHeaderClass,
    tableHeaderWrapperClass,
    tableRecapClass,
    tableTitleClass,
  } from '../DataTable/DataTable.css.ts';
  import { FiltersManager } from '../filters';
  import DataTablePageSizeSelector from './DataTablePageSizeSelector/DataTablePageSizeSelector.vue';
  import DataTablePaginator from './DataTablePaginator/DataTablePaginator.vue';
  import DataTableSelection from './DataTableSelection/DataTableSelection.vue';
  import { paginatedTableFooterClass } from './PaginatedDataTable.css.ts';
  import { PaginatedDataTableProps } from './PaginatedDataTable.types.ts';

  const props = withDefaults(defineProps<PaginatedDataTableProps>(), {
    loading: false,
    alternating: false,
    searchFieldName: FilterTypes.TEXT_SEARCH,
    borderCell: false,
    recap: false,
    mustSort: false,
    selectable: false,
    hideGeneralFilter: false,
    hideTextSearch: false,
    hideSelectAll: false,
    showIndex: false,
    fixedCheckbox: false,
    checkboxColumnWidth: 36,
    fixedIndex: false,
    indexColumnWidth: 36,
    tableMinHeight: 400,
    itemsPerPageOptions: () => ITEMS_PER_PAGE_DEFAULT_OPTIONS,
  });

  const { t } = useLocale('table');

  const emits = defineEmits<{
    paginationAndSort: [params: ServerOptions, filters: FiltersForTable];
    search: [searchText: string, serverOptions: ServerOptions];
    filter: [OnChangeFiltersEvent];
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    rowClick: [row: any];
  }>();

  defineOptions({
    inheritAttrs: false,
  });

  const attrs = useAttrs();
  const slots = useSlots();

  const filtersStore = useFilters(props.filterNamespace);

  const tableHasActions = computed(() => {
    return props.headers.some((header) => header.value === 'actions');
  });

  const hasFooterSlot = computed(() => {
    return slots && !!slots?.footer;
  });

  const dataTableClasses = computed(() => {
    return `tundr-table ${dataTableContainerClass}
    ${props.recap ? tableRecapClass : ''}
    ${hasFooterSlot.value ? ' with-footer' : ''}
    ${props.selectable ? ' selectable' : ''}
    ${props.clickableRows ? ' clickable' : ''}
    ${props.hideSelectAll ? ' hide-all-selector' : ''}
    ${tableHasActions.value ? ' table-with-actions' : ''}`;
  });

  const {
    tableCustomHeight,
    itemsSlotsNames,
    onRowClick,
    rowIsClickable,
    resetTableScroll,
  } = useDataTableInternals({
    tableMinHeight: props.tableMinHeight,
    tableHeight: props.height,
    emits,
  });

  const serverOptionSortBy = ref<string | string[] | undefined>();
  const serverOptionSortByType = ref<SortType | SortType[] | undefined>();

  const serverOptions = computed<ServerOptions>({
    get() {
      return {
        page: props.paginationOptions.currentPage,
        rowsPerPage: props.paginationOptions.rowsPerPage,
        sortBy: serverOptionSortBy?.value ?? props.sortOptions?.sortBy,
        sortType: serverOptionSortByType.value ?? props.sortOptions?.sortType,
      };
    },
    set(newValue) {
      const headerConfigFromSortName = props.headers.find((header) => {
        return newValue.sortBy === header.value && !!header.sortFieldName;
      });

      serverOptionSortByType.value = newValue.sortType;
      serverOptionSortBy.value = headerConfigFromSortName
        ? headerConfigFromSortName.sortFieldName
        : newValue.sortBy;

      emits(
        'paginationAndSort',
        { ...newValue, sortBy: serverOptionSortBy.value },
        { ...filtersStore.getFiltersForTable.value },
      );
    },
  });

  const maxPages = computed(() => {
    return Math.ceil(
      props.paginationOptions.count / props.paginationOptions.rowsPerPage,
    );
  });

  const onSetPageSize = (pageSize: number) => {
    resetTableScroll();
    emits(
      'paginationAndSort',
      {
        ...serverOptions.value,
        page: 1,
        rowsPerPage: pageSize,
      },
      { ...filtersStore.getFiltersForTable.value },
    );
  };

  const onSetPage = (page: number) => {
    resetTableScroll();
    emits(
      'paginationAndSort',
      {
        ...serverOptions.value,
        page: page,
        rowsPerPage: props.paginationOptions.rowsPerPage,
      },
      { ...filtersStore.getFiltersForTable.value },
    );
  };

  const onGoToFirstPage = () => {
    resetTableScroll();
    emits(
      'paginationAndSort',
      {
        ...serverOptions.value,
        rowsPerPage: props.paginationOptions.rowsPerPage,
        page: 1,
      },
      { ...filtersStore.getFiltersForTable.value },
    );
  };

  const onGoToLastPage = () => {
    resetTableScroll();
    emits(
      'paginationAndSort',
      {
        ...serverOptions.value,
        rowsPerPage: props.paginationOptions.rowsPerPage,
        page: maxPages.value,
      },
      { ...filtersStore.getFiltersForTable.value },
    );
  };

  const onGoToNextPage = () => {
    resetTableScroll();
    emits(
      'paginationAndSort',
      {
        ...serverOptions.value,
        rowsPerPage: props.paginationOptions.rowsPerPage,
        page: props.paginationOptions.currentPage + 1,
      },
      { ...filtersStore.getFiltersForTable.value },
    );
  };

  const onGoToPrevPage = () => {
    resetTableScroll();
    emits(
      'paginationAndSort',
      {
        ...serverOptions.value,
        rowsPerPage: props.paginationOptions.rowsPerPage,
        page: props.paginationOptions.currentPage - 1,
      },
      { ...filtersStore.getFiltersForTable.value },
    );
  };

  const onEditFilters = (filters?: FiltersForTable) => {
    emits('filter', {
      ...filters,
    });
  };
</script>

<template>
  <div :class="tableHeaderWrapperClass" v-if="!hideGeneralFilter">
    <div :class="tableHeaderClass">
      <div v-if="tableTitle" :class="tableTitleClass">
        {{ tableTitle }}
      </div>
      <FiltersManager
        v-if="filterNamespace"
        @editFilters="onEditFilters"
        :filter-namespace="filterNamespace"
        :filterVersion="filterVersion"
        :searchFieldName="searchFieldName"
        :searchFieldPlaceholder="searchFieldPlaceholder"
        :filters-config="filtersConfig"
        :hide-search="hideTextSearch"
      >
        <template #extra-filters-content>
          <slot name="extra-filters-content" />
        </template>
        <template #additional-filters>
          <slot name="additional-filters"></slot>
        </template>
      </FiltersManager>
    </div>
  </div>
  <div :class="tableContainerClass">
    <EasyDataTable
      :table-class-name="dataTableClasses"
      :headers="headers"
      :items="items"
      :alternating="alternating"
      :borderCell="borderCell"
      :show-index="showIndex"
      :no-hover="recap"
      :fixed-checkbox="fixedCheckbox"
      :checkbox-column-width="checkboxColumnWidth"
      :fixed-index="fixedIndex"
      :index-column-width="indexColumnWidth"
      :table-min-height="tableMinHeight"
      :theme-color="themeVars?.colors.brand.primary.x50 ?? '#f5f5f5'"
      :rows-items="itemsPerPageOptions"
      :server-items-length="paginationOptions?.count"
      :loading="loading"
      :must-sort="true"
      :searchFieldPlaceholder="searchFieldPlaceholder"
      :body-item-class-name="itemClassNameFunction(headers)"
      :body-row-class-name="rowClassNameFunction({ rowIsClickable })"
      :header-item-class-name="headerClassNameFunction"
      :table-height="tableCustomHeight"
      hide-footer
      v-model:server-options="serverOptions"
      @click-row="onRowClick"
      v-bind="attrs"
    >
      <template v-for="slot of itemsSlotsNames" :key="slot" #[slot]="slotProps">
        <slot :name="slot" v-bind="slotProps" />
      </template>

      <template #loading>
        <slot name="loading">
          <div :class="centeredContainerClass">
            <Loader />
          </div>
        </slot>
      </template>
      <template #empty-message>
        <div :class="centeredContainerClass">
          <template v-if="filtersStore.isFilteringOrSearching.value">
            <Result
              :svg-width="120"
              :svg-height="150"
              :svg-image="noResults"
              :message="t('table.no_items')"
              :subtitle="t('table.no_items_subtitle_filtered')"
            />
          </template>
          <template v-else>
            <slot name="empty-message">
              <Result
                :svg-width="120"
                :svg-height="150"
                :svg-image="noResults"
                :message="t('table.no_items')"
                :subtitle="t('table.no_items_subtitle')"
              />
            </slot>
          </template>
        </div>
      </template>
    </EasyDataTable>
  </div>
  <div :class="paginatedTableFooterClass">
    <slot name="rowSize">
      <DataTablePageSizeSelector
        :rows-per-page="paginationOptions.rowsPerPage"
        :itemsPerPageOptions="itemsPerPageOptions"
        @setPageSize="onSetPageSize"
        :loading="loading"
      />
    </slot>
    <slot name="selection">
      <DataTableSelection
        v-if="!loading && selectable"
        data-test-id="items-selected"
        :entityName="entityName"
        :itemsSelected="(attrs['items-selected'] as [])?.length"
        :maxItemsSelectable="maxItemsSelectable"
      />
    </slot>
    <slot name="paginator">
      <DataTablePaginator
        :totalItems="paginationOptions.count"
        :itemsPerPage="paginationOptions.rowsPerPage"
        :loading="loading"
        :currentPage="paginationOptions.currentPage"
        :isLastPage="paginationOptions.currentPage === maxPages"
        :isFirstPage="paginationOptions.currentPage === 1"
        @go-to-first-page="onGoToFirstPage"
        @go-to-last-page="onGoToLastPage"
        @next-page="onGoToNextPage"
        @prev-page="onGoToPrevPage"
        @go-to-Page="onSetPage"
      />
    </slot>
  </div>
</template>

<style>
  @import '../DataTable/DataTable-override.css';
</style>
