<script setup lang="ts">
  import { useLocale } from '@tundr/i18n';
  import { themeVars } from '@tundr/theme';
  import noResults from '@tundr/theme-assets/images/no-results.svg';
  import { Loader, Result } from '@tundr/ui-kit';
  import { computed, useAttrs, useSlots } from 'vue';
  import EasyDataTable from 'vue3-easy-data-table';
  import 'vue3-easy-data-table/dist/style.css';
  import { useDataTableInternals } from '../../composables/use-data-table-internals.ts';
  import { FiltersForTable, OnChangeFiltersEvent } from '../../store';
  import {
    FilterTypes,
    headerClassNameFunction,
    itemClassNameFunction,
    rowClassNameFunction,
  } from '../../utils';
  import DataTableSelection from '../PaginatedDataTable/DataTableSelection/DataTableSelection.vue';
  import { FiltersManager } from '../filters';
  import {
    centeredContainerClass,
    dataTableContainerClass,
    tableContainerClass,
    tableFooterClass,
    tableHeaderClass,
    tableHeaderWrapperClass,
    tableRecapClass,
    tableTitleClass,
  } from './DataTable.css';
  import { DataTableProps, SortType } from './DataTable.types.ts';

  const props = withDefaults(defineProps<DataTableProps>(), {
    loading: false,
    alternating: false,
    searchFieldName: FilterTypes.TEXT_SEARCH,
    recap: false,
    borderCell: false,
    selectable: false,
    hideGeneralFilter: false,
    hideTextSearch: false,
    showIndex: false,
    hideSelectAll: false,
    fixedCheckbox: false,
    checkboxColumnWidth: 36,
    fixedIndex: false,
    indexColumnWidth: 36,
    sortBy: '',
    sortType: SortType.ASC,
    rowsPerPage: 10000,
    tableMinHeight: 400,
  });

  const { t } = useLocale('table');

  const attrs = useAttrs();
  const slots = useSlots();

  const emits = defineEmits<{
    search: [searchText: string];
    filter: [OnChangeFiltersEvent];
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    rowClick: [row: any];
  }>();

  const tableHasActions = computed(() => {
    return props.headers.some((header) => header.value === 'actions');
  });

  const hasFooterSlot = computed(() => {
    return !!slots['footer'];
  });

  const dataTableClasses = computed(() => {
    return `tundr-table ${dataTableContainerClass}
    ${props.recap ? tableRecapClass : ''}
    ${props.selectable ? ' selectable' : ''}
    ${hasFooterSlot.value ? ' with-footer' : ''}
    ${props.clickableRows ? ' clickable' : ''}
    ${props.hideSelectAll ? ' hide-all-selector' : ''}
    ${tableHasActions.value ? ' table-with-actions' : ''}
    ${props.class}`;
  });

  const { tableCustomHeight, itemsSlotsNames, onRowClick, rowIsClickable } =
    useDataTableInternals({
      tableMinHeight: props.tableMinHeight,
      tableHeight: props.height,
      emits,
    });

  const onEditFilters = (filters?: FiltersForTable) => {
    emits('filter', {
      ...filters,
    });
  };
</script>

<template>
  <div :class="tableHeaderWrapperClass" v-if="!hideGeneralFilter">
    <div :class="tableHeaderClass">
      <div v-if="tableTitle" :class="tableTitleClass">
        {{ tableTitle }}
      </div>
      <FiltersManager
        v-if="filterNamespace"
        :searchFieldName="searchFieldName"
        @editFilters="onEditFilters"
        :filter-namespace="filterNamespace"
        :filterVersion="filterVersion"
        :searchFieldPlaceholder="searchFieldPlaceholder"
        :filtersConfig="filtersConfig"
        :hide-search="hideTextSearch"
      >
        <template #extra-filters-content
          ><slot name="extra-filters-content"
        /></template>
        <template #additional-filters>
          <slot name="additional-filters"></slot>
        </template>
      </FiltersManager>
    </div>
  </div>
  <div :class="tableContainerClass">
    <EasyDataTable
      :table-class-name="dataTableClasses"
      :headers="headers"
      :items="items"
      :no-hover="recap"
      :must-sort="mustSort"
      :alternating="alternating"
      :borderCell="borderCell"
      :show-index="showIndex"
      :fixed-checkbox="fixedCheckbox"
      :checkbox-column-width="checkboxColumnWidth"
      :rowsPerPage="rowsPerPage"
      :fixed-index="fixedIndex"
      :index-column-width="indexColumnWidth"
      :table-min-height="tableMinHeight"
      :theme-color="themeVars?.colors.brand.primary.x50 ?? '#f5f5f5'"
      :loading="loading"
      :body-item-class-name="itemClassNameFunction(headers)"
      :header-item-class-name="headerClassNameFunction"
      :body-row-class-name="rowClassNameFunction({ rowIsClickable })"
      :table-height="tableCustomHeight"
      :sortBy="sortBy"
      :sortType="sortType"
      @click-row="onRowClick"
      hide-footer
      v-bind="attrs"
    >
      <template v-for="slot of itemsSlotsNames" :key="slot" #[slot]="slotProps">
        <slot :name="slot" v-bind="slotProps" />
      </template>

      <template #loading>
        <slot name="loading">
          <div :class="centeredContainerClass">
            <Loader />
          </div>
        </slot>
      </template>

      <template #empty-message>
        <slot name="empty-message">
          <div :class="centeredContainerClass">
            <Result
              :svg-width="120"
              :svg-height="150"
              :svg-image="noResults"
              :message="t('table.no_items')"
              :subtitle="t('table.no_items_subtitle')"
            />
          </div>
        </slot>
      </template>
    </EasyDataTable>
    <slot name="footer">
      <div
        :class="tableFooterClass"
        v-if="selectable && (attrs['items-selected'] as [])?.length"
      >
        <slot name="selection">
          <DataTableSelection
            v-if="!loading && selectable"
            data-test-id="items-selected"
            :entityName="entityName"
            :itemsSelected="(attrs['items-selected'] as [])?.length"
            :maxItemsSelectable="maxItemsSelectable"
          />
        </slot>
      </div>
    </slot>
  </div>
</template>

<style>
  @import './DataTable-override.css';
</style>
