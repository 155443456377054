import { RouteRecordRaw } from 'vue-router';
import { paymentValidationKeywordsRoutesDictionary } from './payment-validation-keywords-routes.dictionary.ts';

export const paymentValidationKeywordsRoutesConfig: RouteRecordRaw = {
  ...paymentValidationKeywordsRoutesDictionary.paymentValidationKeywords.root,
  redirect: {
    name: paymentValidationKeywordsRoutesDictionary.paymentValidationKeywords
      .list.name,
  },
  children: [
    {
      ...paymentValidationKeywordsRoutesDictionary.paymentValidationKeywords
        .list,
      component: () =>
        import('../pages/List/PaymentValidationKeywordsListPage.vue'),
    },
    {
      ...paymentValidationKeywordsRoutesDictionary.paymentValidationKeywords
        .create,
      component: () =>
        import('../pages/Create/CreatePaymentValidationKeywordPage.vue'),
    },
    {
      ...paymentValidationKeywordsRoutesDictionary.paymentValidationKeywords
        .edit,
      component: () =>
        import('../pages/Edit/EditPaymentValidationKeywordPage.vue'),
      props: true,
    },
  ],
};
