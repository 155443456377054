<script setup lang="ts">
  import {
    Tooltip as ArkTooltip,
    TooltipContent,
    TooltipPositioner,
    TooltipTrigger,
  } from '@ark-ui/vue';
  import {
    tooltipContentClass,
    tooltipDescriptionClass,
    tooltipPositionerClass,
    tooltipTitleClass,
  } from './Tooltip.css';
  import { computed, useSlots } from 'vue';
  import { GLOBAL_OVERLAYS_TARGET } from '../config/global-overlays-target';
  import { PlacementOptions } from '../utils/placement';

  export type TooltipProps = {
    label?: string;
    description?: string;
    openDelay?: number;
    interactive?: boolean;
    placement?: PlacementOptions;
  };

  const props = withDefaults(defineProps<TooltipProps>(), {
    showArrow: false,
    interactive: true,
    openDelay: 100,
    placement: PlacementOptions.TOP,
  });

  const slots = useSlots();
  const component = computed(() => slots.default!()[0]);

  defineOptions({
    inheritAttrs: false,
  });

  const hasDescription = computed(() => props.description !== undefined);

  const titleClasses = computed(() => {
    return hasDescription.value
      ? [tooltipTitleClass, tooltipDescriptionClass]
      : tooltipTitleClass;
  });
</script>

<template>
  <ArkTooltip
    v-if="label"
    :interactive="interactive"
    :openDelay="openDelay"
    :positioning="{ placement: placement }"
  >
    <TooltipTrigger>
      <component :is="component" v-bind="$props" />
    </TooltipTrigger>

    <Teleport :to="`.${GLOBAL_OVERLAYS_TARGET}`">
      <TooltipPositioner :class="tooltipPositionerClass">
        <TooltipContent :class="tooltipContentClass" v-bind="$attrs">
          <span :class="titleClasses">
            {{ label }}
          </span>
          <p v-if="description" :class="tooltipDescriptionClass">
            {{ description }}
          </p>
        </TooltipContent>
      </TooltipPositioner>
    </Teleport>
  </ArkTooltip>
  <slot v-else v-bind="$props" />
</template>
