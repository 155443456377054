<script lang="ts" setup>
  import { rowCenterClass } from '@tundr/theme';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import { computed, onMounted, useTemplateRef } from 'vue';
  import VueMultiselect from 'vue-multiselect';
  import FontIcon from '../../FontIcon/FontIcon.vue';
  import { InputWidthVariant } from '../InputWrapper';
  import {
    errorClass,
    inputHeaderClass,
    inputLabelClass,
    inputWidthClass,
    inputWrapperClass,
  } from '../InputWrapper/InputWrapper.css.ts';
  import './Select-override.css.ts';
  import { selectInputWrapperClass, customCaretClass } from './Select.css';
  import { OptionObject, SelectProps } from './Select.types.ts';
  import { useLocale } from '@tundr/i18n';

  const { t } = useLocale('ui-kit');

  const props = withDefaults(defineProps<SelectProps>(), {
    searchable: true,
    clearOnSelect: true,
    closeOnSelect: true,
    showLabels: false,
    showNoOptions: true,
    resettable: false,
    showNoResults: true,
    allowEmpty: false,
    taggable: false,
    disabled: false,
    meta: undefined,
    internalSearch: true,
    width: InputWidthVariant.AUTO,
  });

  const elClass = computed(() => props.class);

  const selectedElRef = useTemplateRef<HTMLInputElement & { isOpen: boolean }>(
    'selectedElRef',
  );

  const emit = defineEmits<{
    blur: [ev?: Event, shouldValidate?: boolean];
    addTag: [value: string];
    search: [value: SelectProps['modelValue']];
    reset: [];
    'update:modelValue': [value: SelectProps['modelValue']];
    select: [value: SelectProps['modelValue']];
  }>();

  const handleChange = (value: SelectProps['modelValue']) => {
    emit('update:modelValue', value);
  };
  const handleBlur = (ev?: Event) => {
    emit('blur', ev);
  };

  const handleTag = (newTag: string) => {
    emit('addTag', newTag);
    const updatedValue = [
      ...((props.modelValue as { label: string; value: string }[]) || []),
      { label: newTag, value: newTag },
    ];
    emit('update:modelValue', updatedValue);
  };

  const handleSearch = (searchValue: string) => {
    emit('search', searchValue);
  };

  const handleSelect = (selectedOption: SelectProps['modelValue']) => {
    emit('select', selectedOption);
    // fix to close the dropdown
    selectedElRef.value!.isOpen = false;
    emit('blur');
  };

  defineOptions({
    inheritAttrs: false,
  });

  const showError = computed<boolean>(() => {
    if (props.meta) {
      return !props.meta.valid && props.meta.touched;
    }
    return !!props.error;
  });

  const handleReset = () => {
    if (props.modelValue) {
      emit('update:modelValue', undefined);
      emit('reset');
    }
  };

  const selectClasses = computed(() => [
    'custom-select',
    inputWidthClass[props.width ?? InputWidthVariant.AUTO],
    props.taggable ? 'taggable-select' : '',
  ]);

  const inputWrapperClasses = computed(() => [
    inputWrapperClass,
    selectInputWrapperClass,
  ]);

  onMounted(() => {
    if (!!props.optionLabel && props.modelValue) {
      const match = props.options?.find(
        (option) => (option as OptionObject).value === props.modelValue,
      );

      if (match) {
        emit('update:modelValue', match);
      }
    }
  });
</script>

<template>
  <div :class="selectClasses">
    <slot name="inputLabel">
      <div :class="inputHeaderClass" v-if="label">
        <label v-if="label" :class="inputLabelClass" :for="id">{{
          label
        }}</label>
      </div>
    </slot>
    <div :class="inputWrapperClasses">
      <div :class="rowCenterClass">
        <VueMultiselect
          ref="selectedElRef"
          :open-direction="openDirection"
          @tag="handleTag"
          @update:model-value="handleChange"
          @blur="handleBlur"
          @select="handleSelect"
          @search-change="handleSearch"
          :model-value="modelValue"
          :options="options"
          :class="elClass"
          :id="id"
          :data-test-id="testId"
          :name="name"
          :label="optionLabel"
          :loading="loading"
          :allow-empty="allowEmpty"
          :searchable="searchable"
          :closeOnSelect="closeOnSelect"
          :clearOnSelect="clearOnSelect"
          :customLabel="customLabel"
          :hideSelected="hideSelected"
          :showLabels="showLabels"
          :disabled="disabled"
          :trackBy="trackBy"
          :meta="meta"
          :taggable="taggable"
          :tagPosition="tagPosition"
          :maxHeight="maxHeight"
          :max="max"
          :optionsLimit="optionsLimit"
          :limit="limit"
          :selectGroupLabel="selectGroupLabel"
          :selectedLabel="selectedLabel"
          :deselectLabel="deselectLabel"
          :deselectGroupLabel="deselectGroupLabel"
          :groupLabel="groupLabel"
          :groupSelect="groupSelect"
          :groupValues="groupValues"
          :preselectFirst="preselectFirst"
          :showNoOptions="showNoOptions"
          :showNoResults="showNoResults"
          :showPointer="showPointer"
          :tabindex="tabindex"
          :optionHeight="optionHeight"
          :multiple="multiple"
          :internalSearch="internalSearch"
          :placeholder="placeholder"
          :tagPlaceholder="tagPlaceholder || t('select.tagPlaceholder')"
        >
          <template v-for="(_, slot) of $slots" v-slot:[slot]="scope"
            ><slot :name="slot" v-bind="scope"
          /></template>
          <template #caret
            ><FontIcon
              :icon-name="UNICONS_ICONS.ANGLE_DOWN"
              :class="[customCaretClass, 'custom-caret']"
          /></template>
          <template #noOptions>
            <span>
              {{ noOptionsMessage || t('select.noOptions') }}
            </span>
          </template>
          <template #noResult>
            <span>
              {{ noResultsMessage || t('select.noResults') }}
            </span>
          </template>
        </VueMultiselect>
        <FontIcon
          v-if="resettable"
          clickable
          :disabled="!modelValue"
          :iconName="UNICONS_ICONS.TIMES"
          @click="handleReset"
        />
      </div>
      <slot name="inputError">
        <div v-if="showError && error" :class="errorClass">
          {{ error }}
        </div>
      </slot>
    </div>
  </div>
</template>
