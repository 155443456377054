import type {
  BodyItemClassNameFunction as externalBodyItemClassNameFunction,
  BodyRowClassNameFunction as externalBodyRowClassNameFunction,
  Header as externalHeader,
  HeaderItemClassNameFunction as externalHeaderItemClassNameFunction,
  Item as externalItem,
} from 'vue3-easy-data-table';
import { Filter } from '../../store';
import { FilterTypes } from '../../utils';
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export type ClickRowArgument<T extends Record<string, any>> = T & {
  isSelected?: boolean;
};
export type BodyItemClassNameFunction = externalBodyItemClassNameFunction;
export type HeaderItemClassNameFunction = externalHeaderItemClassNameFunction;
export type BodyRowClassNameFunction = externalBodyRowClassNameFunction;
export enum SortType {
  ASC = 'asc',
  DESC = 'desc',
}

export type Column = externalHeader & {
  align?: 'left' | 'center' | 'right';
  filterable?: boolean;
  searchable?: boolean;
  sortFieldName?: string;
  lastFixed?: boolean;
};

export type Item = externalItem;

export type DataTableProps = {
  class?: string;
  tableTitle?: string;
  recap?: boolean;
  mustSort?: boolean;
  headers: Column[];
  items: Item[];
  loading?: boolean;
  alternating?: boolean;
  clickableRows?: boolean;
  borderCell?: boolean;
  selectable?: boolean;
  showIndex?: boolean;
  fixedCheckbox?: boolean;
  checkboxColumnWidth?: number;
  hideSelectAll?: boolean;
  fixedIndex?: boolean;
  indexColumnWidth?: number;
  rowsPerPage?: number;
  tableMinHeight?: number;
  hideGeneralFilter?: boolean;
  sortBy?: string;
  sortType?: SortType;
  height?: number;
  maxItemsSelectable?: number;
  entityName?: string;
  hideTextSearch?: boolean;
  filtersConfig?: Filter[];
  filterNamespace?: string;
  filterVersion?: string;
  searchFieldPlaceholder?: string;
  searchFieldName?: string | FilterTypes.TEXT_SEARCH;
};
