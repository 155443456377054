<script setup lang="ts">
  import { pageSizeSelectorClass } from './DataTablePageSizeSelector.css.ts';
  import { useLocale } from '@tundr/i18n';
  import {
    InputWidthVariant,
    Select,
    SelectDropdownOpenDirection,
    SelectProps,
  } from '@tundr/ui-kit';

  export type DataTablePageSizeSelectorProps = {
    rowsPerPage?: number;
    itemsPerPageOptions?: number[];
    loading?: boolean;
  };

  const { t } = useLocale('table');

  defineProps<DataTablePageSizeSelectorProps>();

  const emits = defineEmits(['setPageSize']);

  const updateRowsPerPageSelect = (value: SelectProps['modelValue']) => {
    if (value) {
      emits('setPageSize', value);
    }
  };
</script>

<template>
  <div :class="pageSizeSelectorClass" id="pageSizeSelector">
    <span>{{ t('table.rows_per_page') }}</span>
    <Select
      :tabindex="-1"
      :searchable="false"
      :openDirection="SelectDropdownOpenDirection.TOP"
      @select="updateRowsPerPageSelect"
      :modelValue="rowsPerPage"
      :width="InputWidthVariant.MIN"
      name="itemsPerPage"
      :options="itemsPerPageOptions || []"
      :disabled="loading"
    />
  </div>
</template>
