<script lang="ts" setup>
  import { rowCenterClass } from '@tundr/theme';
  import { SearchInput } from '@tundr/ui-kit';
  import { useDebounceFn } from '@vueuse/core';
  import { computed, onMounted, onUnmounted, ref } from 'vue';
  import 'vue3-easy-data-table/dist/style.css';
  import { useFilters } from '../../../composables';
  import {
    ActiveFilter,
    Filter,
    FiltersForTable,
    useFiltersStore,
  } from '../../../store/';
  import { FilterTypes } from '../../../utils';
  import { ActiveFiltersRow, FilterSelector } from '../index';
  import {
    searchInputClass,
    tableFiltersRowClass,
    tableFiltersWrapperClass,
  } from './FiltersManager.css';

  const props = defineProps<{
    hideSearch?: boolean;
    filterNamespace?: string;
    searchFieldName?: string;
    searchFieldPlaceholder?: string;
    filterVersion?: string;
    filtersConfig?: ActiveFilter[];
  }>();

  const emit = defineEmits<{
    removeFilter: [filter: Filter];
    editFilters: [filters?: FiltersForTable];
  }>();

  const currentFilterStore = useFilters(props.filterNamespace);
  const filtersStore = useFiltersStore();

  const searchTextValueInStore = computed(() => {
    return currentFilterStore.getFilterValue(
      props.searchFieldName || FilterTypes.TEXT_SEARCH,
    );
  });

  const searchInputModel = ref(searchTextValueInStore.value);

  onMounted(() => {
    /**
     * If filterVersion changes, we reset the filters for that namespace.
     */
    const filterCurrentVersion = currentFilterStore.getFilterVersion.value;
    if (filterCurrentVersion && filterCurrentVersion !== props.filterVersion) {
      currentFilterStore.initializeFilters();
    }
    currentFilterStore.setFilterVersion({
      filterNamespace: props.filterNamespace,
      filterVersion: props.filterVersion,
    });
  });

  /**
   * Subscribe to store actions and emit editFilters event
   */
  const unsusbscribe = filtersStore.$onAction(({ name, after }) => {
    if (
      name === 'applyFilterValue' ||
      name === 'removeFilter' ||
      name === 'initializeFilter'
    ) {
      after(() => {
        const filters = currentFilterStore.getFiltersForTable.value;
        emit('editFilters', filters);
      });
    }
  });

  onUnmounted(() => {
    unsusbscribe();
  });

  const onSearch = useDebounceFn((searchText: string) => {
    searchInputModel.value = searchText;
    currentFilterStore.applyFilterValue({
      filterType: FilterTypes.TEXT_SEARCH,
      value: searchText,
      filterFieldName: props.searchFieldName || FilterTypes.TEXT_SEARCH,
    });
  }, 500);

  const onEditFilters = (filters?: FiltersForTable) => {
    emit('editFilters', filters);
  };

  /**
   * When we clear all filters, we reset the search input
   */
  const onClearFilters = () => {
    searchInputModel.value = '';
  };
</script>

<template>
  <div :class="tableFiltersWrapperClass">
    <div :class="tableFiltersRowClass">
      <div :class="rowCenterClass">
        <SearchInput
          :placeholder="searchFieldPlaceholder"
          v-if="!hideSearch"
          clearable
          autofocus
          :class="searchInputClass"
          :name="`search-${filterNamespace}`"
          :model-value="searchInputModel as string"
          :test-id="`search-${filterNamespace}`"
          @update:modelValue="onSearch"
        />
        <slot name="filterSelector">
          <FilterSelector
            v-if="filtersConfig && filterNamespace"
            :filterNamespace="filterNamespace"
            :filtersConfig="filtersConfig"
            @onClearFilters="onClearFilters"
          />
        </slot>
      </div>
      <slot name="extra-filters-content"></slot>
    </div>
    <ActiveFiltersRow
      v-if="filterNamespace"
      @editFilters="onEditFilters"
      :filterNamespace="filterNamespace"
    >
      <template #additional-filters>
        <slot name="additional-filters"></slot>
      </template>
    </ActiveFiltersRow>
  </div>
</template>
