<script lang="ts" setup>
  import { useLocale } from '@tundr/i18n';
  import { ButtonVariant } from '@tundr/theme';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import { Button, FontIcon, Menu, MenuItem } from '@tundr/ui-kit';
  import { ActiveFilter } from '../../../store/';
  import {
    filterButtonClass,
    filterSelectorMenuClass,
    resetFiltersClass,
    filtersSelectorWrapperClass,
    filterItemButtonClass,
  } from './FilterSelector.css';
  import { useFilters } from '../../../composables';
  import { computed } from 'vue';
  import { FilterTypes, getFilterTypeIconByType } from '../../../utils';

  const { t } = useLocale('table');

  const props = defineProps<{
    filterNamespace: string;
    filtersConfig: ActiveFilter[];
  }>();

  const emit = defineEmits<{
    onClearFilters: [];
  }>();

  const currentFilterStore = useFilters(props.filterNamespace);
  const aFilterIsActive = computed(() => currentFilterStore.isFiltering.value);

  /**
   * We remove the text search filter and all the already active filters from the list.
   */
  const availableFiltersList = computed(() => {
    return props.filtersConfig.filter((filter) => {
      return (
        filter.filterType !== FilterTypes.TEXT_SEARCH &&
        !currentFilterStore.filterIsActive(filter.filterFieldName)
      );
    });
  });

  const onSelectFilter = (filter: ActiveFilter) => {
    const newFilter = { ...filter, modelValue: undefined };
    currentFilterStore.addFilter(newFilter);
  };

  const onClearFilters = () => {
    emit('onClearFilters');
    currentFilterStore.resetAllfilters();
  };
</script>

<template>
  <div :class="filtersSelectorWrapperClass">
    <Menu>
      <template #trigger>
        <Button
          :disabled="availableFiltersList.length === 0"
          :class="filterButtonClass"
          :variant="ButtonVariant.transparentOutlined"
          :label="t('table.filters.filters')"
        >
          <template #iconLeft>
            <FontIcon :iconName="UNICONS_ICONS.FILTER" />
          </template>
          <template #iconRight>
            <FontIcon :iconName="UNICONS_ICONS.ANGLE_DOWN" />
          </template>
        </Button>
      </template>
      <template v-for="filter of filtersConfig" :key="filter.filterFieldName">
        <MenuItem
          v-if="filter.filterType !== FilterTypes.TEXT_SEARCH"
          :class="filterItemButtonClass"
          :icon="filter.iconName || getFilterTypeIconByType(filter.filterType)"
          is-font-icon
          :label="filter.filterLabel"
          @click="onSelectFilter(filter)"
        />
      </template>
      <div :class="filterSelectorMenuClass"></div>
    </Menu>
    <span
      @click="onClearFilters"
      :class="resetFiltersClass"
      v-if="aFilterIsActive"
      >{{ t('table.filters.reset_filters') }}</span
    >
  </div>
</template>
