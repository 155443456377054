import { ButtonVariant } from '@tundr/theme';
import { ICONS } from '@tundr/theme-assets/font-icon/icons';
import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
import { ComponentCommonTypes } from '../utils/commonTypes';

export type ButtonProps = ComponentCommonTypes & {
  role?: string;
  type?: ButtonTypes;
  size?: ButtonSizes;
  iconName?: ICONS | UNICONS_ICONS;
  isFontIcon?: boolean;
  variant?: ButtonVariant;
  textAlign?: ButtonAlignmentVariant;
  width?: ButtonWidthVariant;
  disabled?: boolean;
  textEllipsis?: boolean;
  loading?: boolean;
  label?: string;
  tooltipText?: string;
  tooltipDescription?: string;
  tooltipClass?: string;
};

export enum ButtonWidthVariant {
  FULL = 'full',
  FIXED = 'fixed',
  AUTO = 'auto',
  MIN = 'min',
}

export enum ButtonAlignmentVariant {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ButtonTypes {
  BUTTON = 'button',
  SUBMIT = 'submit',
  RESET = 'reset',
}

export enum ButtonSizes {
  SMALL = 'small',
  DEFAULT = 'medium',
  NO_PADDING = 'no-padding',
}
