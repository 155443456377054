<script setup lang="ts">
  import { computed } from 'vue';
  import { ComponentCommonTypes } from '../../utils/commonTypes.ts';
  import { InputWidthVariant } from '../InputWrapper';
  import InputWrapper from '../InputWrapper/InputWrapper.vue';
  import {
    radioboxElementClass,
    radioboxLabelClass,
    radioboxWrapperClass,
  } from './Radiobox.css.ts';

  /**
   * This component is not fully developed and has to be used as controlled component
   */
  type RadioboxProps = ComponentCommonTypes & {
    value?: string | unknown;
    label?: string;
    inputLabel?: string;
    name: string;
    disabled?: boolean;
    modelValue: string | unknown;
  };

  const props = defineProps<RadioboxProps>();

  const emits = defineEmits(['update:modelValue']);

  const isChecked = computed(() => {
    return props.modelValue === props.value;
  });

  const onChange = (e: Event) => {
    emits('update:modelValue', (e.target as HTMLInputElement).value);
  };
</script>

<template>
  <InputWrapper
    :id="id"
    :inputLabel="inputLabel"
    :width="InputWidthVariant.FULL"
  >
    <div :class="radioboxWrapperClass">
      <input
        :data-test-id="testId"
        :class="radioboxElementClass"
        type="radio"
        :checked="isChecked"
        :value="value"
        @change="onChange"
        :name="name"
        :id="name"
        :disabled="disabled"
      />
      <slot name="value">
        <label
          :class="radioboxLabelClass"
          v-if="value"
          :for="name"
          @change="onChange"
          >{{ label }}</label
        >
      </slot>
    </div>
  </InputWrapper>
</template>
