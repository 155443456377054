import {
  BodyItemClassNameFunction,
  BodyRowClassNameFunction,
  Column,
  HeaderItemClassNameFunction,
} from '../components/DataTable/';
import {
  cellAlignmentVariant,
  clickableRowClass,
  tableCellClass,
} from '../components/DataTable/DataTable.css.ts';

export const headerClassNameFunction: HeaderItemClassNameFunction = (
  column: Column,
): string => {
  return `header-item-${column.align || 'left'} ${
    column.lastFixed ? 'header-item-last-fixed' : ''
  }`;
};

export const itemClassNameFunction: (
  headers: Column[],
) => BodyItemClassNameFunction =
  (headers: Column[]) =>
  (columnName: string): string => {
    const classes = [tableCellClass];

    const columnConfig: Column | undefined = headers.find(
      (col) => col.value === columnName,
    );

    if (columnConfig) {
      classes.push(cellAlignmentVariant[columnConfig.align || 'left']);
    }

    return classes.join(' ');
  };

type RowClassNameFunctionPayload = {
  rowIsClickable?: boolean;
};
export const rowClassNameFunction: (
  payload: RowClassNameFunctionPayload,
) => BodyRowClassNameFunction =
  ({ rowIsClickable }) =>
  (): string => {
    if (rowIsClickable) {
      return clickableRowClass;
    }
    return '';
  };

export const ITEMS_PER_PAGE_DEFAULT_OPTIONS = [10, 25, 50, 100];

export const HIDDEN_SELECTION_CLASS_NAME = 'hidden-selection';

export const NOT_SELECTABLE_ROW_CLASS_NAME = 'not-selectable-row';

export const DISABLED_ROW_CLASS_NAME = 'disabled-row';
