<script lang="ts" setup>
  import { DatePicker } from '@tundr/ui-kit';
  import { computed, ref } from 'vue';
  import { singleDateFilterWrapperClass } from './SingleDateFilter.css';
  import { useFilters } from '../../../../composables';
  import ApplyFilterButton from '../../ApplyFilterButton/ApplyFilterButton.vue';
  import { FilterTypes, simulateEscapeKeyPress } from '../../../../utils';
  import { useLocale } from '@tundr/i18n';

  const props = defineProps<{
    filterNamespace: string;
    filterLabel: string;
    filterFieldName: string;
    min?: Date;
    max?: Date;
    modelValue?: Date | null;
  }>();

  defineOptions({
    inheritAttrs: false,
  });

  const { t } = useLocale('table');

  const currentFilterStore = useFilters(props.filterNamespace);

  const date = ref<Date>(props.modelValue as Date);

  const emit = defineEmits<{
    'update:modelValue': [date: Date | null];
  }>();

  const onApplyFilter = () => {
    emit('update:modelValue', date.value);
    currentFilterStore.applyFilterValue({
      filterFieldName: props.filterFieldName,
      value: date.value,
      filterType: FilterTypes.DATE,
    });
    simulateEscapeKeyPress();
  };

  const maxDate = computed(() => {
    return (props.max as Date) || undefined;
  });

  const minDate = computed(() => {
    return (props.min as Date) || undefined;
  });
</script>

<template>
  <div :class="singleDateFilterWrapperClass">
    <DatePicker
      v-model="date"
      :label="filterLabel"
      :name="filterFieldName"
      required
      :placeholder="t('table.filters.date_range.start_date.placeholder')"
      autofocus
      :max-date="maxDate"
      :min-date="minDate"
    />
  </div>
  <ApplyFilterButton @click="onApplyFilter" />
</template>
