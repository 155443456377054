<script lang="ts" setup>
  import { useLocale } from '@tundr/i18n';
  import { textEllipsisClass } from '@tundr/theme';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import { FontIcon, Menu } from '@tundr/ui-kit';
  import { onUnmounted } from 'vue';
  import { Filter } from '../../../store/';
  import { parseModelFromFilterType } from '../../../utils';
  import { FilterByType } from '../filter-components';
  import {
    activeFilterChipClass,
    activeFilterChipLabelClass,
    activeFilterChipValueClass,
    activeFilterContainerClass,
    openEditFilterIconClass,
    openEditFilterIconWrapperClass,
    removeActiveFilterIconClass,
    removeActiveFilterIconWrapperClass,
  } from './ActiveFilterChip.css';

  const props = defineProps<{
    filterNamespace: string;
    filter: Filter;
  }>();

  const { d } = useLocale('');

  const emit = defineEmits<{
    removeFilter: [filter: Filter];
    editFilter: [filter: Filter];
  }>();

  const onEditFilter = (filter: Filter) => {
    emit('editFilter', filter);
  };

  const onRemoveFilter = (filter: Filter) => {
    emit('removeFilter', filter);
  };

  onUnmounted(() => {
    if (!props.filter.modelValue) {
      onRemoveFilter(props.filter);
    }
  });
</script>

<template>
  <Menu :closeOnIteract="false" :open="!filter.modelValue">
    <template #trigger>
      <div :class="activeFilterChipClass">
        <span :class="activeFilterChipLabelClass">{{
          filter.filterLabel
        }}</span>
        <div
          v-if="filter.modelValue"
          :class="activeFilterChipValueClass"
          :title="
            parseModelFromFilterType(filter.filterType, filter.modelValue, d)
          "
        >
          <span :class="textEllipsisClass">{{
            parseModelFromFilterType(filter.filterType, filter.modelValue, d)
          }}</span>
        </div>
        <div v-if="filter.modelValue" :class="openEditFilterIconWrapperClass">
          <FontIcon
            clickable
            :class="openEditFilterIconClass"
            :icon-name="UNICONS_ICONS.ANGLE_DOWN"
          />
        </div>
        <div
          :class="removeActiveFilterIconWrapperClass"
          @click="onRemoveFilter(filter)"
        >
          <FontIcon
            clickable
            :class="removeActiveFilterIconClass"
            :icon-name="UNICONS_ICONS.MULTIPLY"
          />
        </div>
      </div>
    </template>
    <div :class="activeFilterContainerClass">
      <FilterByType
        @update:modelValue="onEditFilter"
        :filterNamespace="filterNamespace"
        v-bind="{ ...$attrs, ...filter }"
      >
        <template #additional-filters>
          <slot name="additional-filters"></slot>
        </template>
      </FilterByType>
    </div>
  </Menu>
</template>
