<script lang="ts" setup>
  import { Radiobox } from '@tundr/ui-kit';
  import { ref } from 'vue';
  import { useFilters } from '../../../../composables';
  import {
    SelectFilterOptions,
    SingleSelectModelValue,
  } from '../../../../store';
  import { FilterTypes, simulateEscapeKeyPress } from '../../../../utils';
  import ApplyFilterButton from '../../ApplyFilterButton/ApplyFilterButton.vue';
  import {
    optionClass,
    selectOneFilterWrapperClass,
  } from './SelectOneFilter.css';

  const props = defineProps<{
    filterNamespace: string;
    filterLabel: string;
    filterFieldName: string;
    options: SelectFilterOptions;
    modelValue?: SingleSelectModelValue;
  }>();

  defineOptions({
    inheritAttrs: false,
  });

  const currentFilterStore = useFilters(props.filterNamespace);

  const selectedItem = ref<SingleSelectModelValue | undefined>(
    props.modelValue,
  );

  const selectedOption = ref<string | undefined>(props.modelValue?.label);

  const emit = defineEmits<{
    'update:modelValue': [SingleSelectModelValue | undefined];
  }>();

  const onApplyFilter = () => {
    emit('update:modelValue', selectedItem.value);
    currentFilterStore.applyFilterValue({
      filterFieldName: props.filterFieldName,
      value: selectedItem.value,
      filterType: FilterTypes.SELECT,
    });
    simulateEscapeKeyPress();
  };

  const onSelectItem = (item: SingleSelectModelValue) => {
    selectedItem.value = item;
    selectedOption.value = item.value;
  };
</script>

<template>
  <div :class="selectOneFilterWrapperClass">
    <div
      :class="optionClass"
      v-for="(option, index) in options"
      :key="option.value"
      @click="() => onSelectItem(option)"
    >
      <Radiobox
        :autofocus="index === 0"
        :id="option.value"
        :name="option.value"
        :value="option.value"
        :label="option.label"
        :modelValue="selectedOption"
        @update:modelValue="() => onSelectItem(option)"
      />
    </div>
  </div>
  <ApplyFilterButton :disabled="!selectedItem" @click="onApplyFilter" />
</template>
