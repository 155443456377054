<script setup lang="ts">
  import { computed } from 'vue';
  import {
    navigationIconClass,
    pageSelectorWrapperClass,
    otherPageItemClass,
    paginatorWrapperClass,
    currentPageItemClass,
    navigationIconWrapperClass,
    paginatorLoadingClass,
  } from './DataTablePaginator.css.ts';
  import { ICONS } from '@tundr/theme-assets/font-icon/icons.ts';
  import { useLocale } from '@tundr/i18n';
  import {
    Pagination,
    PaginationPrevPageTrigger,
    PaginationList,
    PaginationListItem,
    PaginationNextPageTrigger,
    PaginationEllipsis,
  } from '@ark-ui/vue';
  import { Loader, LoaderSize, Icon } from '@tundr/ui-kit';
  import { disabledClass } from '@tundr/theme';

  export type DataTablePaginatorProps = {
    currentPage: number;
    totalItems: number;
    isLastPage: boolean;
    itemsPerPage: number;
    isFirstPage: boolean;
    loading?: boolean;
  };

  const props = defineProps<DataTablePaginatorProps>();

  const { t } = useLocale('table');

  const emits = defineEmits<{
    goToFirstPage: [];
    goToLastPage: [];
    nextPage: [];
    prevPage: [];
    goToPage: [page: number];
  }>();

  const onGoToPage = (page: number) => {
    emits('goToPage', page);
  };

  const canGoBack = computed(() => {
    return !(props.loading || props.isFirstPage);
  });
  const canGoForward = computed(() => {
    return !(props.loading || props.isLastPage);
  });

  const paginatorClasses = computed(() => ({
    [paginatorWrapperClass]: true,
    [paginatorLoadingClass]: props.loading,
  }));
</script>

<template>
  <div v-if="!loading && totalItems > 0" :class="paginatorClasses">
    <span>
      <b>{{
        `${currentPage * itemsPerPage - itemsPerPage + 1}-${
          currentPage * itemsPerPage > totalItems
            ? totalItems
            : currentPage * itemsPerPage
        }`
      }}</b>
      {{ t('table.of') }} {{ totalItems }} {{ t('table.results') }}</span
    >
    <Pagination
      :class="pageSelectorWrapperClass"
      :count="totalItems"
      :page-size="itemsPerPage"
      :page="currentPage"
      :sibling-count="1"
      v-slot="{ pages }"
    >
      <PaginationPrevPageTrigger
        :class="[navigationIconWrapperClass, !canGoBack ? disabledClass : '']"
        ><Icon
          :class="navigationIconClass"
          :icon-name="ICONS.ARROW_SMALL"
          :rotation="90"
          clickable
          :disabled="!canGoBack"
          @click="canGoBack && emits('prevPage')"
          :testId="'go-to-prev-page'"
      /></PaginationPrevPageTrigger>
      <PaginationList :class="pageSelectorWrapperClass">
        <template v-for="(page, index) in pages">
          <PaginationListItem
            v-if="page.type === 'page'"
            :key="page"
            :value="page.value"
            :data-test-id="`page-item-${page.value}`"
            :class="
              currentPage === page.value
                ? currentPageItemClass
                : otherPageItemClass
            "
            @click="currentPage !== page.value && onGoToPage(page.value)"
          >
            {{ page.value }}
          </PaginationListItem>
          <PaginationEllipsis v-else :key="index" :index="index">
            &#8230;
          </PaginationEllipsis>
        </template>
      </PaginationList>
      <PaginationNextPageTrigger
        :class="[
          navigationIconWrapperClass,
          !canGoForward ? disabledClass : '',
        ]"
      >
        <Icon
          :class="navigationIconClass"
          :icon-name="ICONS.ARROW_SMALL"
          :rotation="270"
          clickable
          :disabled="!canGoForward"
          @click="canGoForward && emits('nextPage')"
          :testId="'go-to-next-page'"
        />
      </PaginationNextPageTrigger>
    </Pagination>
  </div>
  <Loader :size="LoaderSize.small" v-else-if="loading" />
  <div v-else />
</template>
