import { isAfter, isBefore, isDate, isValid } from 'date-fns';
import { OLDEST_VALID_DATE, YOUNGEST_VALID_DATE } from '../config'; /*

/*
  Characters exluded: ! # $ % & * ( ) _ = + [ { ] } \ | ; : "  < > , / ? @ ` ~
*/
import { SimpleValidationRuleFunction } from '../types';
export const nameOrSurname: SimpleValidationRuleFunction<string> = (value) => {
  if (!value) {
    return true;
  }
  return /^[a-zA-Z\u00C0-\u017F\-.' ]+$/.test(value);
};

export const italianZipCode: SimpleValidationRuleFunction<string> = (value) => {
  if (!value) {
    return true;
  }
  return /^\d{5}$/.test(value);
};

export const swanEmail: SimpleValidationRuleFunction<string> = (value) => {
  if (!value) {
    return true;
  }
  return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
    value,
  );
};

export const taxId: SimpleValidationRuleFunction<string> = (value) => {
  if (!value) {
    return true;
  }
  return /^[A-z0-9]{16}$/i.test(value);
};

export const internationalPhoneNumber: SimpleValidationRuleFunction<string> = (
  value,
) => {
  if (!value) {
    return true;
  }
  return /^\+?\d+$/.test(value);
};

export const taxIdOrUndefined: SimpleValidationRuleFunction<string> = (
  value,
) => {
  if (!value) {
    return true;
  }
  return taxId(value, []);
};

export const dateIsNotTooOld: SimpleValidationRuleFunction<string | Date> = (
  value,
) => {
  if (!value) {
    return true;
  }

  const date: Date = isDate(value)
    ? (value as Date)
    : new Date(value as string);
  if (isBefore(date, OLDEST_VALID_DATE)) {
    return false;
  }
  return true;
};

export const dateIsNotTooYoung: SimpleValidationRuleFunction<string | Date> = (
  value,
) => {
  if (!value) {
    return true;
  }

  const date: Date = isDate(value)
    ? (value as Date)
    : new Date(value as string);
  if (isAfter(date, YOUNGEST_VALID_DATE)) {
    return false;
  }
  return true;
};

export const dateMustBeAfterAnother: SimpleValidationRuleFunction<
  string | undefined,
  (string | undefined)[]
> = (a, [b]): boolean => {
  if (!a || !b) {
    return true;
  }
  return isAfter(new Date(a as string), new Date(b as string));
};

export const atLeastOneRequired: SimpleValidationRuleFunction<
  string | undefined,
  (string | undefined)[]
> = (a, [b]): boolean => {
  return Boolean(a || b);
};

export const fieldsMustBeTheSame: SimpleValidationRuleFunction<
  string | undefined,
  (string | undefined)[]
> = (a, [b]): boolean => {
  return a === b;
};

export const fieldIsRequiredIfAnother: SimpleValidationRuleFunction<
  string | undefined,
  (string | undefined)[]
> = (a, b): boolean => {
  return b[0] === b[1] ? !!a : true;
};

export const password: SimpleValidationRuleFunction<string> = (value) => {
  if (!value) {
    return true;
  }
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/])[A-Za-z\d~`!@#$%^&*()_\-+={[}\]|:;"'<,>.?/]{8,}$/.test(
    value,
  );
};

export const date: SimpleValidationRuleFunction<Date | string> = (value) => {
  if (!value) {
    return true;
  }
  const date: Date = isDate(value)
    ? (value as Date)
    : new Date(value as string);
  return isValid(date);
};

export const time: SimpleValidationRuleFunction<string> = (value) => {
  if (!value) {
    return true;
  }
  return /^(?:[01]\d|2[0-3]):[0-5]\d$/.test(value);
};

export const cityValidation: SimpleValidationRuleFunction<string> = (value) => {
  if (!value) {
    return true;
  }
  return /^[a-zA-Z\s'']+$/.test(value);
};
