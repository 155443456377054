<script lang="ts" setup>
  import { ref } from 'vue';
  import { useFilters } from '../../../../composables';
  import ApplyFilterButton from '../../ApplyFilterButton/ApplyFilterButton.vue';
  import {
    selectMultipleFilterWrapperClass,
    optionClass,
    optionLabelClass,
  } from './SelectMultipleFilter.css';
  import { Checkbox, InputWidthVariant } from '@tundr/ui-kit';
  import { FilterTypes, simulateEscapeKeyPress } from '../../../../utils';
  import {
    SelectFilterOptions,
    SingleSelectModelValue,
  } from '../../../../store';

  const props = defineProps<{
    filterNamespace: string;
    filterLabel: string;
    filterFieldName: string;
    options: SelectFilterOptions;
    modelValue?: SingleSelectModelValue[];
  }>();

  defineOptions({
    inheritAttrs: false,
  });

  const currentFilterStore = useFilters(props.filterNamespace);

  const selectedItems = ref<SingleSelectModelValue[]>(props.modelValue || []);

  const emit = defineEmits<{
    'update:modelValue': [SingleSelectModelValue[]];
  }>();

  const onApplyFilter = () => {
    emit('update:modelValue', selectedItems.value);
    currentFilterStore.applyFilterValue({
      filterFieldName: props.filterFieldName,
      value: selectedItems.value,
      filterType: FilterTypes.SELECT_MULTIPLE,
    });
    simulateEscapeKeyPress();
  };

  const onSelectItem = (item: SingleSelectModelValue) => {
    if (selectedItems.value.includes(item)) {
      selectedItems.value = selectedItems.value.filter(
        (i: SingleSelectModelValue) => i !== item,
      );
    } else {
      selectedItems.value = [...selectedItems.value, item];
    }
  };

  const checkboxIsSelected = (
    item: SingleSelectModelValue['value'],
  ): boolean => {
    return !!selectedItems.value.find(
      (i: SingleSelectModelValue) => i.value === item,
    );
  };
</script>

<template>
  <div :class="selectMultipleFilterWrapperClass">
    <div
      :class="optionClass"
      v-for="option in options"
      :key="option.value"
      @click="() => onSelectItem(option)"
    >
      <Checkbox
        :width="InputWidthVariant.MIN"
        :name="`${filterFieldName}-${option.value}`"
        :id="`${filterFieldName}-${option.value}`"
        :modelValue="checkboxIsSelected(option.value)"
      />
      <label
        @click="() => onSelectItem(option)"
        :class="optionLabelClass"
        :for="`${filterFieldName}-${option.value}`"
        >{{ option.label }}</label
      >
    </div>
  </div>
  <ApplyFilterButton :disabled="!selectedItems.length" @click="onApplyFilter" />
</template>
