<script lang="ts" setup>
  import TextInput from '../TextInput/TextInput.vue';
  import { disabledClass } from '@tundr/theme';
  import FontIcon from '../../FontIcon/FontIcon.vue';
  import {
    closeIconClass,
    searchIconClass,
    searchInputClass,
  } from './SearchInput.css.ts';
  import { useLocale } from '@tundr/i18n';
  import { TextInputProps } from '../TextInput';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';

  export type SearchInputProps = Omit<TextInputProps, 'type' | 'label'> & {
    clearable?: boolean;
  };

  const { t } = useLocale('ui-kit');

  const props = defineProps<SearchInputProps>();

  const emit = defineEmits(['update:modelValue', 'blur']);

  const handleChange = (val: string) => {
    emit('update:modelValue', val);
  };
  const handleBlur = (val: string) => {
    emit('blur', val);
  };

  const onClear = () => {
    emit('update:modelValue', '');
  };

  const searchIconClasses = [
    searchIconClass,
    {
      [disabledClass]: props.disabled || props.loading,
    },
  ];

  const closeIconClasses = [
    closeIconClass,
    {
      [disabledClass]: props.disabled || props.loading,
    },
  ];
</script>

<template>
  <TextInput
    :class="searchInputClass"
    :error="error"
    :test-id="testId"
    label=""
    :autofocus="autofocus"
    :meta="meta"
    :disabled="disabled"
    :loading="loading"
    :modelValue="modelValue"
    :name="name"
    :placeholder="!placeholder ? t('search_input.placeholder') : placeholder"
    :type="'text'"
    :width="width"
    @update:model-value="handleChange"
    @blur="handleBlur"
  >
    <template #iconLeft>
      <FontIcon :icon-name="UNICONS_ICONS.SEARCH" :class="searchIconClasses" />
    </template>
    <template #iconRight v-if="clearable && modelValue">
      <FontIcon
        @click="onClear"
        clickable
        :icon-name="UNICONS_ICONS.TIMES_CIRCLE"
        :class="closeIconClasses"
      />
    </template>
  </TextInput>
</template>
