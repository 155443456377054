<script lang="ts" setup>
  import { applyButtonWrapperClass } from './ApplyFilterButton.css';
  import { Button, ButtonWidthVariant } from '@tundr/ui-kit';
  import { ButtonVariant } from '@tundr/theme';
  import { useLocale } from '@tundr/i18n';

  const { t } = useLocale('table');

  defineOptions({
    inheritAttrs: false,
  });
</script>

<template>
  <div :class="applyButtonWrapperClass">
    <Button
      :width="ButtonWidthVariant.FULL"
      v-bind="$attrs"
      :variant="ButtonVariant.primary"
      >{{ t('table.filters.apply') }}</Button
    >
  </div>
</template>
