<script setup lang="ts">
  import { useLocale } from '@tundr/i18n';
  import {
    dataTableSelectionContainerClass,
    dataTableSelectionClass,
  } from './DataTableSelection.css';
  import { computed } from 'vue';

  const { t } = useLocale('table');

  const props = defineProps<{
    itemsSelected: number;
    maxItemsSelectable?: number;
    entityName?: string;
  }>();

  const maxReached = computed(() => {
    if (
      props.maxItemsSelectable &&
      props.itemsSelected >= props.maxItemsSelectable
    ) {
      return true;
    }
    return false;
  });
</script>

<template>
  <div :class="dataTableSelectionContainerClass" v-if="itemsSelected">
    <div :class="dataTableSelectionClass">
      {{ itemsSelected }} {{ entityName || t('table.items') }}
      {{ t('table.selected_items') }}
      <template v-if="maxItemsSelectable && !maxReached"
        >{{
          `${t('table.max_items_selectable', {
            entityName: entityName || t('table.items'),
            maxItemsSelectable: maxItemsSelectable?.toString(),
          })}`
        }}
      </template>
      <template v-if="maxReached">{{
        t('table.max_reached', { entityName })
      }}</template>
    </div>
  </div>
</template>
