import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const suppliersRoutesDictionary = moduleRouteDictionaryFactory(
  'suppliers',
  'suppliers',
  {
    list: 'list',
    create: 'create',
    update: 'update/:supplierId',
    detail: 'detail/:supplierId',
    createLocation: 'detail/:supplierId/locations/create',
    editLocation: 'detail/:supplierId/locations/:locationId/edit/',
    createService: 'detail/:supplierId/services/create',
    editService: 'detail/:supplierId/services/:id/edit/',
    voucherCodes: 'detail/:supplierId/voucher-codes/:fileId',
  },
);
