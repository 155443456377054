import { computed } from 'vue';
import {
  ActiveFilter,
  ApplyFilterValueProps,
  Filter,
  FiltersForTable,
} from '../store';
import { useFiltersStore } from '../store/filters.store';
import { FilterTypes } from '../utils';

/**
 * This composable works like an utility hook to manage filters for a specific namespace.
 * Once it is initialized, you can operate directly on that filter namespace.
 * @param filterNamespace
 * @returns
 */
export function useFilters(filterNamespace?: string) {
  const filtersStore = useFiltersStore();

  const getFilters = computed(() => {
    if (!filterNamespace) return;
    return filtersStore.filters[filterNamespace];
  });

  const initializeFilters = () => {
    if (!filterNamespace) return;
    filtersStore.initializeFilter(filterNamespace);
  };

  const addFilter = (newFilter: ActiveFilter) => {
    if (!filterNamespace) return;
    filtersStore.addFilter(filterNamespace, newFilter);
  };

  const removeFilter = (filterFieldName: string) => {
    if (!filterNamespace) return;
    filtersStore.removeFilter(filterNamespace, filterFieldName);
  };

  const resetAllfilters = () => {
    if (!filterNamespace) return;
    filtersStore.initializeFilter(filterNamespace);
  };

  const isFiltering = computed(() => {
    if (!filterNamespace) return;
    return (
      filtersStore.filters[filterNamespace] &&
      filtersStore.filters[filterNamespace].filter(
        (filter) => filter.filterType !== FilterTypes.TEXT_SEARCH,
      ).length > 0
    );
  });

  const isSearching = computed(() => {
    if (!filterNamespace) return;
    const searchFilter =
      filtersStore.filters[filterNamespace] &&
      filtersStore.filters[filterNamespace].filter(
        (filter) => filter.filterType === FilterTypes.TEXT_SEARCH,
      )[0];
    return (
      filtersStore.filters[filterNamespace] &&
      searchFilter &&
      !!searchFilter.modelValue
    );
  });

  const isFilteringOrSearching = computed(() => {
    return isFiltering.value || isSearching.value;
  });

  const applyFilterValue = (payload: ApplyFilterValueProps) => {
    const { filterFieldName, filterType, value, toKey, fromKey } = payload;
    if (!filterNamespace) return;
    filtersStore.applyFilterValue({
      entityName: filterNamespace,
      filterFieldName,
      value,
      filterType,
      toKey,
      fromKey,
    });
  };

  const filterIsActive = (filterFieldName: string): boolean | undefined => {
    if (!filterNamespace) return;
    return filtersStore.filterIsActive(filterNamespace, filterFieldName);
  };

  const setFilterVersion = (payload: {
    filterNamespace?: string;
    filterVersion?: string;
  }) => {
    if (!payload.filterNamespace || !payload.filterVersion) return;
    filtersStore.setFilterVersion(
      payload.filterNamespace,
      payload.filterVersion,
    );
  };

  const getFilterVersion = computed(() => {
    if (!filterNamespace) return;
    return filtersStore.filtersVersionMap[filterNamespace];
  });

  /**
   * Extract the filters for the "filter" event emitted in the table.
   * Returns: Record<string, {
   filterType: FilterTypes;
   value: ActiveFilterModelValue;
   }
   >
   */
  const getFiltersForTable = computed(() => {
    if (!filterNamespace) return;
    const res: FiltersForTable = {};
    filtersStore.filters &&
      filtersStore.filters[filterNamespace] &&
      filtersStore.filters[filterNamespace].forEach((filter: Filter) => {
        res[filter.filterFieldName] = {
          ...filter,
          filterType: filter.filterType,
          value: filter.modelValue,
        };
      });
    return res;
  });

  const getFilterValue = (filterFieldName: string) => {
    if (!filterNamespace) return;
    return getFiltersForTable.value?.[filterFieldName]?.value;
  };

  return {
    getFiltersForTable,
    applyFilterValue,
    filterIsActive,
    isFiltering,
    resetAllfilters,
    getFilterVersion,
    setFilterVersion,
    initializeFilters,
    getFilters,
    addFilter,
    removeFilter,
    isSearching,
    getFilterValue,
    isFilteringOrSearching,
  };
}
