import { DateLike, DatetimeFormatType } from '@tundr/i18n';
import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
import { I18nTFunction } from '@tundr/validation';
import { endOfDay, startOfDay } from 'date-fns';
import {
  ActiveFilterModelValue,
  DateFilterModelValue,
  DateRangeFilterModelValue,
  DateRangeFilterProps,
  Filter,
  FiltersForTable,
  MultipleSelectModelValue,
  SingleDateFilterProps,
  SingleSelectModelValue,
} from '../../store';

export enum FilterTypes {
  TEXT_SEARCH = 'needle',
  DATE = 'date',
  DATE_RANGE = 'dateRange',
  SELECT = 'select',
  SELECT_MULTIPLE = 'selectMultiple',
  SELECT_PAGINATED = 'selectPaginated',
  TYPED_TEXT = 'typedText',
}

export const getFilterTypeIconByType = (type: FilterTypes) => {
  switch (type) {
    case FilterTypes.DATE:
      return UNICONS_ICONS.CALENDAR_ALT;
    case FilterTypes.DATE_RANGE:
      return UNICONS_ICONS.CALENDER;
    case FilterTypes.SELECT:
      return UNICONS_ICONS.LIST_UI_ALT;
    case FilterTypes.SELECT_PAGINATED:
      return UNICONS_ICONS.LIST_UI_ALT;
    case FilterTypes.SELECT_MULTIPLE:
      return UNICONS_ICONS.CHECK_SQUARE;
    case FilterTypes.TYPED_TEXT:
      return UNICONS_ICONS.TEXT;
  }
};

export const getOptionsFromEnum = (
  enumType: Record<string, string>,
  translationKey: string,
  t: I18nTFunction,
) => {
  return Object.keys(enumType).map((key) => ({
    label: t(`${translationKey}.${enumType[key]}`),
    value: key,
  }));
};

export const simulateEscapeKeyPress = () => {
  document.dispatchEvent(
    new KeyboardEvent('keydown', {
      key: 'Escape',
      keyCode: 27,
      code: 'Escape',
      which: 27,
    }),
  );
};

export const parseModelFromFilterType = (
  type: FilterTypes,
  modelValue: ActiveFilterModelValue,
  d: (date: DateLike, formatType: DatetimeFormatType) => string,
) => {
  switch (type) {
    case FilterTypes.DATE:
      return d(
        modelValue as DateFilterModelValue,
        DatetimeFormatType.SHORT_DATE,
      );
    case FilterTypes.DATE_RANGE:
      // eslint-disable-next-line no-case-declarations
      const { startDate, endDate } = modelValue as DateRangeFilterModelValue;

      if (startDate && endDate) {
        return `${d(startDate, DatetimeFormatType.SHORT_DATE)} - ${d(endDate, DatetimeFormatType.SHORT_DATE)}`;
      }
      if (startDate) {
        return `${d(startDate, DatetimeFormatType.SHORT_DATE)}`;
      }
      if (endDate) {
        return `${d(endDate, DatetimeFormatType.SHORT_DATE)}`;
      }
      break;
    case FilterTypes.SELECT:
    case FilterTypes.SELECT_PAGINATED:
      return (
        (modelValue as SingleSelectModelValue).label ||
        (modelValue as SingleSelectModelValue).value
      );
    case FilterTypes.SELECT_MULTIPLE:
      return (modelValue as MultipleSelectModelValue)
        .map((m) => m.label || m.value)
        .join(', ');
  }
};

export const transformFiltersToPayload = (
  payload: Record<string, unknown> = {},
  filters?: FiltersForTable,
): Record<string, unknown> => {
  const res: Record<string, unknown> = { ...payload };
  if (filters) {
    Object.keys(filters).forEach((key) => {
      const filter = filters[key];
      if (filter) {
        switch (filter.filterType) {
          case FilterTypes.TEXT_SEARCH:
            if (filter.value) {
              res[key] = filter.value as string;
            }
            break;
          case FilterTypes.DATE_RANGE:
            filter.value = filter.value as DateRangeFilterModelValue;
            // eslint-disable-next-line no-case-declarations
            const resFromFieldName = filter.value.startDate
              ? (filter.fromKey ?? 'from')
              : undefined;

            // eslint-disable-next-line no-case-declarations
            const resToFieldName = filter.value.endDate
              ? (filter.toKey ?? 'to')
              : undefined;

            if (resFromFieldName)
              res[resFromFieldName] = (
                filter.value as DateRangeFilterModelValue
              ).startDate
                ? startOfDay(
                    new Date(
                      (filter.value as DateRangeFilterModelValue)
                        .startDate as Date,
                    ),
                  ).toISOString()
                : '';
            if (resToFieldName)
              res[resToFieldName] = (filter.value as DateRangeFilterModelValue)
                .endDate
                ? endOfDay(
                    new Date(
                      (filter.value as DateRangeFilterModelValue)
                        .endDate as Date,
                    ),
                  ).toISOString()
                : '';
            break;

          case FilterTypes.DATE:
            res[key] = (filter.value as DateFilterModelValue)
              ? startOfDay(
                  new Date(filter.value as DateFilterModelValue),
                ).toISOString()
              : '';
            break;
          case FilterTypes.SELECT:
          case FilterTypes.SELECT_PAGINATED:
            res[key] = (filter.value as SingleSelectModelValue).value;
            break;
          case FilterTypes.SELECT_MULTIPLE:
            res[key] = ((filter.value as MultipleSelectModelValue) || [])
              .map((m) => m.value)
              .join(',');
            break;
          case FilterTypes.TYPED_TEXT:
            res[key] = filter.value as string;
            break;
        }
      }
    });
  }
  return { ...res, ...payload };
};

export function reviveDates(
  filterObject: Record<string, Filter[]>,
): Record<string, Filter[]> {
  const parseDate = (date: string | Date): Date =>
    typeof date === 'string' ? new Date(date) : date;

  const reviveFilter = (filter: Filter) => {
    if (
      filter &&
      filter.filterType === FilterTypes.DATE &&
      (filter as SingleDateFilterProps).modelValue
    ) {
      return {
        ...filter,
        modelValue: parseDate(
          (filter as SingleDateFilterProps).modelValue as string,
        ),
        max: parseDate((filter as SingleDateFilterProps).max as string),
        min: parseDate((filter as SingleDateFilterProps).min as string),
      };
    }
    if (
      filter.filterType === FilterTypes.DATE_RANGE &&
      (filter as DateRangeFilterProps).modelValue?.startDate &&
      (filter as DateRangeFilterProps).modelValue?.endDate
    ) {
      return {
        ...filter,
        startDate: {
          max: parseDate(
            (filter as DateRangeFilterProps).startDate!.max as string,
          ),
          min: parseDate(
            (filter as DateRangeFilterProps).startDate!.min as string,
          ),
        },
        endDate: {
          max: parseDate(
            (filter as DateRangeFilterProps).endDate!.max as string,
          ),
          min: parseDate(
            (filter as DateRangeFilterProps).endDate!.min as string,
          ),
        },
        modelValue: {
          startDate: parseDate(
            (filter as DateRangeFilterProps).modelValue!.startDate as string,
          ),
          endDate: parseDate(
            (filter as DateRangeFilterProps).modelValue!.endDate as string,
          ),
        },
      };
    }
    return filter;
  };

  return Object.fromEntries(
    Object.entries(filterObject).map(([key, filters]) => [
      key,
      filters.map(reviveFilter),
    ]),
  );
}
